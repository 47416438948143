.actualNews .container,
.actualNews .btnTop {
  border-radius: 0 !important;
}

.actualNews .card {
  width: 100%;
  max-width: 490px;
  height: auto;
  display: flex;
  justify-content: space-between;
  background-size: cover;
  background-position: center;
  border: none;
  border-radius: 0%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  object-fit: cover;
}
.actualNews .card-img-top {
  width: 100% !important;
  height: 130px !important;
  border-radius: 0;
}

.actualNews .card:hover .card-body {
  transition: all ease-in-out 0.2s;
  background-color: #c8181c;
  color: #fff;
}
.actualNews .card-body {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.actualNews .card-title {
  font-size: 1.1rem;
}

.actualNews .card-text {
  font-size: 0.9rem;
}

.actualNews .btn-light {
  background-color: #c8181c;
  color: #ffffff;
}
.actualNews .btn-light.active {
  background-color: #780006;
  color: #ffffff;
}

.actualNews .btn-secondary {
  background-color: #c8181c;
  color: #ffffff;
  border: none;
  margin-top: 1rem;
  border-radius: 0;

}
.actualNews .btnTitle {
  border-radius: 0%;
  font-size: 20px;
}
.actualNews .btnTitle:hover {
  background-color: #a51415;
}

.actualNews .btnMore {
  background-color: #c8181c;
  color: #fff;
}

.actualNews .btnMore:hover {
  background-color: #a51415;
  color: black;
}

.actualNews .card-body a span{
  position: absolute;
  bottom: -4px;
  right: 0px;
  padding-right: 5px;
}

@media (max-width: 1280px) {
  .actualNews .card-body a {
    font-size: 14px;
  }
}
@media (max-width: 1200px) {
  .actualNews .card-body a {
    font-size: 12px;
  }
}
@media (max-width: 1024px) {
  .actualNews .btnTitle {
    padding: 3px 0px;
  }
  .actualNews .card-img-top {
    width: 100% !important; 
    height: 100% !important;
  }
  .actualNews .card-body a span{
    width: 100%;
    font-size: 10px;
  }
}

@media (max-width: 1024px) {
  .actualNews .categories-news-container{
    grid-template-columns: repeat(2, 1fr);
    display: grid;
  }
}

@media (max-width: 991px) {
  .actualNews .card {
    height: 100px;
  }
  .actualNews .card-img-top {
    width: 100% !important;
    height: 100% !important;
  }
  .actualNews .card-body a {
    font-size: 11px;
  }
  .actualNews .btnTitle {
    font-size: 14px;
  }
  .actualNews .card-body a span{
    width: 100%;
    font-size: 10px;
  }
}
@media (max-width: 835px) {
  .actualNews .card {
    height: 100px;
  }
  .actualNews .card-img-top {
    width: 100% !important;
    height: 100% !important;
  }
  .actualNews .card-body a {
    font-size: 11px;
  }
  .actualNews .btnTitle {
    font-size: 14px;
  }
  .actualNews .card-body a span{
    width: 100%;
    font-size: 9px;
  }
}
@media (max-width: 820px) {
  .actualNews .card {
    height: 100px;
  }
  .actualNews .card-img-top {
    width: 100% !important;
    height: 100% !important;
  }
  .actualNews .card-body a {
    font-size: 11px;
  }
  .actualNews .btnTitle {
    font-size: 14px;
  }
  .actualNews .card-body a span{
    width: 100%;
    font-size: 9px;
  }
  .actualNews .categories-news-container{
    grid-template-columns: repeat(2, 1fr);
    display: grid;
  }
}
@media (max-width:800px) {
  .actualNews .card-body a span{
    width: 100%;
    font-size: 9px;
  }

}

@media (max-width: 768px) {
  .actualNews .btnTitle {
    font-size: 14px;
  }
  .actualNews .btn-group {
    padding: 0;
    margin: 0;
  }
  .actualNews .card {
    height: 100% !important;
  }
  .actualNews .card .card-img-top {
    width: 100% !important;
    height: 100% !important;

  }
  .actualNews .card-body a span{
    width: 100%;
    font-size: 8px;
  }

  .actualNews .categories-news-container{
    grid-template-columns: repeat(1, 1fr);
    display: grid;
  }

  .actualNews .card-body a{
    font-size: 14px;
  }

  .actualNews .card{
    width: 100%;
    max-width: none;
  }

  .cardImgLink{
    width: 30%;
  }

  .actualNews .card {
    height: 50px;
  }
}
@media (max-width: 567px) {
  /* .actualNews {
    padding-right: 4%;
  } */
  .actualNews .categories-news-container{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .actualNews .card {
    height: 100px !important;
    width: 95%;
    display: flex;
    flex-direction: column; 
    /* border: solid red 1px; */
  }

  .actual-news-title {
    font-size: 12px;
  }

  .actualNews .card-img-top {
    width: 90% !important; 
    height: 100% !important;
  }
  .actualNews .card-body a span{
    width: 100%;
    font-size: 8px;
  } 

  .cardImgLink{
    width: 40%;
  }
 
}
@media (max-width: 430px) {
  .actualNews .card {
    height: 80px;
  }
  .actualNews .card .card-img-top {
    width: 100% !important;
    height: 100% !important;

  }
  .actualNews .card-body a{
    font-size: 12px;
  }
  .actualNews .card-body a span{
    width: 100%;
    font-size: 8px;
  }
  .actual-news-title {
    font-size: 12px;
  }
  .actualNews .btn-secondary{
    width: 80px;
    height: 20px;
    font-size: 12px;
    padding: 1px;
  }
}
@media (max-width: 390px) {
  .actualNews .card {
    height: 80px !important;
  }
  .actualNews .card .card-img-top {
    width: 100% !important;
    height: 100% !important;

  }
  .actualNews .card-body a{
    font-size: 12px;
  }
  .actualNews .card-body a span{
    width: 100%;
    font-size: 8px;
  }
  .actual-news-title {
    font-size: 12px;
  }
  .actualNews .btn-secondary{
    width: 80px;
    height: 20px;
    font-size: 12px;
    padding: 1px;
  }
}
