/* @media (max-width: 1024px) {
    .homepage-container-div{
      margin-left: 50px;
      margin-right: 50px;
    }
  } */

@media (max-width: 430px) {
    .homepage-container-div{
      padding-top: 20px;
    }
  } 

