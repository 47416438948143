
.hp .colorRed {
  background-color: #F73C29;
}
.hp .colorOrange {
  background-color: #E96F27;
}
.hp .colorGreen {
  background-color: #277F45;
}
.hp .colorSky{
  background-color: #52A79D;
}
.hp .colorLightGreen {
  background-color: #1BB21D;
}
.hp .colorBlue {
  background-color: #4B679B;
}
.hp {
  margin: 2% 2.2% 3% 4%;
  padding: 0%;
}
.hp .container {
  width: 100%;
  /* margin-left: -2px; */
  margin-bottom: 1%;
  margin-top: 2%;
  background-color: transparent;
}
.hp .hp_banner_box {
  width: 100%;
  display: flex;
  justify-content: center;
  object-fit: cover;
  margin-left: -11px;
  padding: 0% 7%;
  margin-bottom: 1px;
  background-color: transparent !important;
}
.hp .hp_banner_left,
.hp .hp_banner_right {
  flex: 1;
  width: 100%;
  max-height: 400px;
  object-fit: cover;
  overflow: hidden;
}
.hp .slider {
  position: relative;
  width: 100%;
  height: 100%;
}
.hp .slider-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.hp .slider-container div {
  height: 100%;
  width: 100%;
}
.hp .slider-container div a {
  width: 100%;
  height: 100% !important;
  object-fit: cover;
}

.hp .slider-container div a img {
  width: 100%;
  height: 100% !important;
}
.hp .slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  background-position: center;
  background-size: contain;
  opacity: 0;
  transition: opacity 0.7s ease;
}
.hp .slide.active {
  opacity: 1;
}
.hp .slide .slideImg {
  width: 110%;
  height: auto;
  object-fit: cover;
  display: block;
}

.hp .prev,
.hp .next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 28px;
  padding: 10px;
  z-index: 10;
}
.hp .prev {
  left: 10px;
}
.hp .next {
  right: 10px;
}
.hp .numbers {
  text-align: center;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}
.hp .number {
  
  display: none;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #bbb;
  margin: 0 4px;
  cursor: pointer;
  line-height: 20px;
  text-align: center;
  transition: background-color 0.3s ease;
}
.hp .slide-title {
  position: absolute;
  top: 1%;
  right: 2%;
  width: 200px !important;
  height: 32px !important;
  background-color: #F73C29;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  color: white;
  z-index: 10;
}
.hp .slide-title h3{
  font-size: 11px;
  width: 100%;
  height: 100%;
  font-weight: bold;
  font-size: x-large;
}
.hp .slide-content {
  position: absolute;
  height: 80px !important;
  bottom: 0;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.7) 25%,
    rgba(0, 0, 0, 1) 100%
  );  display: flex;
  justify-content: start;
  align-items: center;
  padding-left: 1%;
  /* border-radius: 5px; */
  color: white;
  font-size: 28px;
  line-height: 24px;

}


.hp .number:hover {
  background-color: #717171;
  color: white;
}
.hp .number.active-number {
  background-color: #717171;
  color: white;
}
.hp .newsTitle {
  width: 100%;
  height: 400px;
}
.hp .newsTop,
.hp .newsDown {
  height: 200px;
  display: flex;
}
.hp .downLinks .imgBox img {
  height: 200px;
}
.hp .imgBox {
  position: relative;
  width: 100%;
  max-width: 800px;
  height: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  transition: all ease-in-out 0.3s;
  object-fit: cover;
  overflow: hidden;
  border: solid 1px white;
}
.hp .imgBox:hover {
  filter: none;
  cursor: pointer;
}
.hp .imgBox:hover img {
  transform: scale(1.1);
  transition: all ease-in-out 0.3s;
}
.hp .image {
  display: inline-flex;
  width: 100%;
  height: 100%;
  display: block;
}
.hp .overlay {
  position: absolute;
  top: -10%;
  left: 3%;
  padding: 0% 2%;
  border-radius: 4px;
  font-size: 11px;
  font-weight: 500;
  z-index: 2;
  color: white;
}
.hp .overlayTwo {
  position: absolute;
  width: 100%;
  height: 23%;
  bottom: 0%;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.5) 25%,
    rgba(0, 0, 0, 0.8) 100%
  );
  z-index: 2;
  display: flex;
  /* padding-bottom: 1% !important; */
  /* border: solid blue 1px; */
}
.hp .overlayTwo p {
  display: inline-block;
  width: 100%;
  height: 100%;
  z-index: 1;
  font-weight: 400;
  font-size: 16px !important;
  display: flex;
  justify-content: start;
  align-items: center;
  padding-left: 3%;
  padding-bottom: 0%;
  padding-top: 5%;
  color: white;
}
.hp .overlayTwo::before {
  content: "";
  position: absolute;
  top: 0%;
  left: 0;
  width: 100%;
  height: 100%;
  /* border: solid blue; */
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.0) 0%,
    rgba(0, 0, 0, 0.5) 25%,
    rgba(0, 0, 0, 0.9) 100%
  );
}
.hp .title {
  background-color: #333;
  color: #fff;
  padding: 10px;
  text-align: center;
}
.hp .downLinks {
  width: 100%;
  height: 100% !important;
  padding: 0% 7%;
  display: flex;
  flex-direction: column;
  object-fit: cover;
  overflow: hidden;
}
.hp .downLinks .row {
  width: 100%;
  height: 100% !important;
}

.hp .downLinks .row .col-4 .imgBox img {
  border: solid 1px white;
  height: 100%;
}
.hp .downLinks .row .col-4 {
  padding: 0;
}
@media screen and (max-width: 1280px) {
  .hp .slide-content{
    padding-top: 2%;
    line-height: 28px;
  }
}
@media screen and (max-width: 1200px) {
  .hp .hp_banner_box {
    padding: 0 12%;
    background-color: white;
  }
  .hp .hp_banner_left,
  .hp .hp_banner_right {
    background-color: white;
    padding: 0%;
    height: 100%;
  }
  .hp .downLinks {
    padding: 0 12% !important;
    transition: all ease-in-out 0.3s;
  }
  .hp .downLinks .row {
    height: 175px;
  }

  .hp .slider {
    position: relative;
    width: 100%;
  }
  .hp .slider-container {
    position: relative;
    width: 100%;
    height: 256px;
    overflow: hidden;
  }
  .hp .slider-container div {
    width: 100%;
    height: 100%;
  }
  .hp .slider-container div a {
    height: 100% !important;
  }

  .hp .slider-container div a img {
    height: 100% !important;
  }
  .hp .newsTitle {
    height: 256px;
  }
  .hp .newsTop,
  .hp .newsDown {
    height: 128px;
  }
  .hp .downLinks {
    display: flex;
    margin-left: 2px;
  }
  .hp .downLinks .imgBox img {
    height: 128px;
  }
  .hp .overlay {
    top: -30%;
  }
  .hp .overlayTwo {
    height: 25%;
  
  }
  .hp .overlayTwo p {
    padding-bottom: 5%;
    font-size: 12px !important;
  } 
}
@media screen and (max-width: 1024px) {
.hp .slide-content{
  height: 60px !important;
  padding-top: 2%;
  font-size: large;
}
}
@media screen and (max-width: 991px) {
  .hp .container {
    margin-top: 0%;
  }
  .hp .hp_banner_box {
    flex-direction: column;
    padding: 0 12%;
  }

  /* .hp .hp_banner_left,.slider{
    height: 273px !important;
  } */
  .hp .hp_banner_left,
  .hp .hp_banner_right {
    padding: 0%;
  }
  .hp .downLinks {
    display: flex;
    margin-left: -7px;
  }
  .hp .downLinks .row {
    height: 150px;
    padding: 0 !important;
    margin: 0 !important;
  }
  .hp .slider {
    position: relative;
    width: 100%;
    height: 356px;
  }
  .hp .slider-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .hp .overlay {
    top: -30%;
    font-size: 8px;
  }
  .hp .overlayTwo {
    padding-bottom: 10%;
  }
  .hp .overlayTwo p {
    font-size: 10px !important;
    margin-top: 1%;
  }  
  .hp .downLinks .overlayTwo p {
    margin-top: 2%;
  }
  .hp .slide-content{
    height: 70px !important;
    padding-top: 2%;
    font-size: x-large;
  }
}
@media screen and (max-width: 835px) {
  .hp .container{
    margin-left: 1%;
  }
  /* .hp .hp_banner_left,.slider{
    height: 225px !important;
  } */
}
@media screen and (max-width: 800px) {
  .hp .container{
    margin-left: 0%;
  }
  .hp .hp_banner_box {
    margin-left: -6px;
  }
}
@media screen and (max-width: 768px) {
  /* .homepage-container-div{
    z-index: -1 !important;
  } */
  .hp .container {
    padding: 0;
    width: 100% !important;
  }
  .hp .hp_banner_box {
    display: flex;
    margin: 0;
    padding: 0;
  }
  .hp .hp_banner_left,
  .hp .hp_banner_right {
    height: 300px;
  }
  .hp .slider {
    height: 300px;
  }
  .hp .downLinks {
    height: 300px;
    padding: 0% !important;
    transition: all ease-in-out 0.3s;
    display: flex;
    margin-left: 0;
  }
  .hp .downLinks .row {
    padding: 0 !important;
    margin: 0 !important;
  }
  .hp .overlay {
    top: -15%;
    font-size: 8px;
  }
  .hp .overlayTwo {
    height: 30%;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.3) 25%,
      rgba(0, 0, 0, 0.9) 100%
    );
    
  }
  .hp .overlayTwo p {
    margin-top: 1%;
    font-size: 12px !important;
    line-height: 10px;
  }
}
@media screen and (max-width: 567px) {
  
  .hp .slider {
    height: 250px;
  }

  .hp .slide {
    width: 100%;
    height: 100%;
  }
  .hp .downLinks .row {
    height: 100px !important;
    padding: 0 !important;
    margin: 0 !important;
  }
  .hp .downLinks {
    display: flex;
    margin-left: -1px;
  }
  .hp .number {
    width: 7px;
    height: 7px;
  }
  .hp .slide-content{
    height: 60px !important;
    padding-top: 2%;
    font-size: larger;
  }
  .hp .overlay {
    top: -25%;
    font-size: 8px;
    padding: 1% 2%;
  }
  .hp .overlayTwo {
    height: 30%;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.3) 25%,
      rgba(0, 0, 0, 0.9) 100%
      );
      line-height: 8px;
    }
    .hp .overlayTwo p {
    font-size: 9px !important;
  }
}
@media screen and (max-width: 430px) {
  

  .hp .overlay {
    top: -25%;
  }
  .hp .overlayTwo {
      line-height: 8px;
    }

}

