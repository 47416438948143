.psm-bottom-carousel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    overflow: hidden;
    /* border: solid blue 1px; */
}

.psm-bottom-carousel-button {
    background: none;
    border: none;
    font-size: 30px;
    cursor: pointer;
    background-color: #C90913;
    color: white;
    padding: 20px 10px;
    margin-left: 5px;
    margin-right: 5px;
    transition: all 0.3s;
}

.psm-bottom-carousel-button:hover {
    background-color: #80080e;
}

.psm-bottom-carousel-inner-wrapper {
    flex: 1;
    overflow: hidden;
    position: relative;
    width: 100%;
}

.psm-bottom-carousel-inner {
    display: flex;
    transition: transform 0.5s ease;
    width: 100%;
}

.psm-bottom-carousel-item {
    flex: 0 0 32.25%;
    height: 280px;
    background-size: cover;
    background-position: center;
    position: relative;
    margin: 0 5px;
    display: flex;
    align-items: flex-end;
    box-shadow: rgba(0, 0, 0, 0.8) 0px -100px 36px -28px inset;
}

.carousel-item-link {
    display: block;
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: inherit;
}

.carousel-item-title {
    /* background: rgba(0, 0, 0, 0.5); */
    color: white;
    padding: 10px;
    width: 100%;
    font-weight: 500;
    box-sizing: border-box;
    height: 3.6em;
    text-align: start;
    position: absolute;
    bottom: 4px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 1.3em;
}

@media (max-width: 2560px) {
    
    .psm-bottom-carousel-item {
        height: 250px;
        flex: 0 0 32.50%;
    }

    .psm-bottom-carousel-button {
        font-size: 18px;
    }
}

@media (max-width: 1920px) {
    
    .psm-bottom-carousel-item {
        height: 250px;
        flex: 0 0 32.50%;
    }

    .psm-bottom-carousel-button {
        font-size: 16px;
    }
}



@media (max-width: 1600px) {
    
    .psm-bottom-carousel-item {
        height: 250px;
        flex: 0 0 32.40%;
    }

    .psm-bottom-carousel-button {
        font-size: 18px;
    }

    .carousel-item-title{
        bottom: -3px;
    }
}

@media (max-width: 1512px) {
    
    .psm-bottom-carousel-item {
        height: 200px;
        flex: 0 0 32.3%;
    }
    .carousel-item-title{
    /* border: solid red; */
    padding-top: 10px !important;
}    
    .psm-bottom-carousel-button {
        font-size: 18px;
    }

    .carousel-item-title{
        bottom: -3px;
    }
}
@media (max-width: 1441px) {
    
    .psm-bottom-carousel-item {
        height: 190px;
        flex: 0 0 32.25%;
    }
    .carousel-item-title{
    /* border: solid red; */
    padding-top: 10px !important;
}    
    .psm-bottom-carousel-button {
        font-size: 18px;
    }

    .carousel-item-title{
        bottom: -3px;
    }
}

@media (max-width: 1367px) {
    
    .psm-bottom-carousel-item {
        height: 200px;
        flex: 0 0 32.22%;
    }

    .psm-bottom-carousel-button {
        font-size: 18px;
    }
}

@media (max-width: 1280px) {
    .psm-bottom-carousel {
        margin-top: 6%;
        margin-bottom: -2%;
    }

    .psm-bottom-carousel-item {
        height: 200px;
        flex: 0 0 32.1%;
        /* border: solid 1px red; */
    }

    
    .psm-bottom-carousel-button {
        font-size: 15px;
    }
}

@media (max-width: 1025px) {
    .psm-bottom-carousel {
        width: 100%;
        
    }

    .psm-bottom-carousel-item {
        height: 170px;
        flex: 0 0 31.90%; 
    }

    .psm-bottom-carousel-button {
        font-size: 15px;
    }


}

@media (max-width: 991px) {
    .psm-bottom-carousel {
        width: 100%;
        
    }

    .psm-bottom-carousel-item {
        height: 170px;
        flex: 0 0 31.62%;
    } 
}


@media (max-width: 836px) {
    .psm-bottom-carousel {
        width: 130%;
        margin-left: -15%;
    }
    .psm-bottom-carousel-item {
        height: 140px;
        flex: 0 0 31.65%;
    }


}

@media (max-width: 820px) {
    .psm-bottom-carousel {
        width: 100%;
        margin-left: 0%;
    }

    .psm-bottom-carousel-button {
        font-size: 15px;
    }
    .psm-bottom-carousel-item {
        height: 130px;
        flex: 0 0 31.55%;
    }
    .carousel-item-title{
        font-size: 14px;
    }
}

@media (max-width: 768px) {
    .psm-bottom-carousel {
        width: 100%;
        
    }
    .psm-bottom-carousel-item {
        height: 120px;
        flex: 0 0 31.35%;
    }

    .psm-bottom-carousel-button {
        font-size: 12px;
        padding: 10px 5px;
        /* Buton boyutunu küçültmek için padding */
    }

    .carousel-item-title{
        bottom: -3px;
    }
}

@media (max-width: 767px) {
    
    .psm-bottom-carousel-item {
        height: 120px;
        flex: 0 0 31.8%;
    }

    
}

@media (max-width: 760px) {
    .psm-bottom-carousel-item {
        height: 120px;
        flex: 0 0 31.75%;
    }

    .psm-bottom-carousel-button {
        font-size: 10px;
        padding: 10px 5px;
        /* Buton boyutunu küçültmek için padding */
    }
}

@media (max-width: 480px) {
    .psm-bottom-carousel-item {
        height: 200px;
        flex: 0 0 97.3%;
    }

    .psm-bottom-carousel-button {
        font-size: 12px;
        padding: 10px 5px;
        /* Buton boyutunu küçültmek için padding */
    }
    .carousel-item-title{
        font-size: 16px;
    }
}

@media (max-width: 430px) {
    .psm-bottom-carousel {
        width: 100%;
        margin-left: 0%;
    }

    .psm-bottom-carousel-item {
        height: 190px;
        flex: 0 0 96.8%;
    }

    .psm-bottom-carousel-button {
        font-size: 10px;
        padding: 10px 5px;
        /* Buton boyutunu küçültmek için padding */
    }
}

@media (max-width: 400px) {
    .psm-bottom-carousel {
        width: 100%;
        margin-left: 0%;
    }

    .psm-bottom-carousel-item {
        height: 160px;
        flex: 0 0 96.45%;
    }

    .psm-bottom-carousel-button {
        font-size: 10px;
        padding: 10px 5px;
        /* Buton boyutunu küçültmek için padding */
    }
}

@media (max-width: 360px) {
    .psm-bottom-carousel {
        width: 100%;
        margin-left: 0%;
    }

    .psm-bottom-carousel-item {
        height: 160px;
        flex: 0 0 96.1%;
    }

    .psm-bottom-carousel-button {
        font-size: 10px;
        padding: 10px 5px;
        /* Buton boyutunu küçültmek için padding */
    }
    .carousel-item-title{
        font-size: 14px;
    }
}