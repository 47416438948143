.live-stream-container {
    margin-bottom: 40px;
}

@media (max-width:2560px) {

    .live-stream-link {
        height: 740px;
    }

}

@media (max-width: 1920px) {

    .live-stream-link {
        height: 721px;
    }

}

@media (max-width: 1512px) {

    .live-stream-link {
        height: 580px;
    }

}

@media (max-width: 1367px) {

    .live-stream-link {
        height: 560px;
    }

}

@media (max-width: 1280px) {

    .live-stream-link {
        height: 515px;
    }

}

@media (max-width: 1200px) {

    .live-stream-link {
        height: 470px;
    }

}

@media (max-width: 1100px) {

    .live-stream-link {
        height: 415px;
    }

}

@media (max-width: 1024px) {

    .live-stream-link {
        height: 435px;
    }

}

@media (max-width: 991px) {

    .live-stream-link {
        height: 383px;
    }

}

@media (max-width: 850px) {

    .live-stream-link {
        height: 365px;
    }

}

@media (max-width: 768px) {

    .live-stream-link {
        height: 414px;
    }

}

@media (max-width: 600px) {

    .live-stream-link {
        height: 320px;
    }

}

@media (max-width: 500px) {

    .live-stream-link {
        height: 253px;
    }

}

@media (max-width: 430px) {

    .live-stream-link {
        height: 225px;
    }

}

@media (max-width: 400px) {

    .live-stream-link {
        height: 208px;
    }

}