.mid-top-ads-container{
    display: flex;
    flex-wrap: wrap !important;
    
    justify-content: space-evenly;
    margin-bottom: 60px;
}

.mid-top-ads-container>a>img {
    height: 300px;
    width: 300px;
}

.mid-bottom-ads-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-top: 10px;
    margin-bottom: 10px;
}

.mid-bottom-ads-container>a>img {
   height: 300px;
    
}

.mid-bottom-ads-container2-web{
    display: flex;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
}



@media (max-width: 1024px) {
    .mid-top-ads-container>a>img {
        height: 200px;
        width: 200px;
    }
    .mid-top-ads-container{
        margin-bottom: 5px;
    }
    .mid-bottom-ads-container>a>img {
        height: 170px;
    }
  }

  @media (max-width: 1023px) {
    .mid-top-ads-container>a>img {
        height: 200px;
        width: 200px;
    }
    .mid-top-ads-container{
        margin-bottom: 5px;
        
    }
    .mid-bottom-ads-container>a>img {
        height: auto;
        width: 100%;
        margin-bottom: 10px;
    }
.mid-bottom-ads-container{
        display: unset;
        
    }

    .mid-bottom-ads-container2>a>img {
        height: 300px;
        width: 300px;  
          
    }

    .mid-bottom-ads-container2{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
       row-gap: 20px;
        align-items: center;
        margin-bottom: 40px;
    }

    .mid-bottom-ads-container2-web{
        display: none;
    }
  }

  @media (max-width: 769px) {
    .mid-top-ads-container{
        margin-bottom: 10px;
        margin-top: 40px;
    }
    .mid-top-ads-container>a>img {
        height: 300px;
        width: 300px;
        margin-bottom: 20px;
    }

    .mid-bottom-ads-container>a>img {
        height: auto;
        width: 90%;
        margin-bottom: 10px;
        margin: 0px auto;
    }

    .mid-bottom-ads-container{
        display: unset;
        
    }

    .mid-bottom-ads-container2>a>img {
        height: 300px;
        width: 300px;  
        margin: 20px auto;
    }

    .mid-bottom-ads-container2{
        display: unset;
        
    }
  }

  @media (max-width: 768px) {
    .advertisement-x {
      width: 100%;
      height: 100%;
    }

    .advertisement-x img,
  .advertisement-x iframe {
    width: 100%;
    height: 100% !important; 
    /* object-fit: cover; */
  }
  }