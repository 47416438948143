.carouselBox {
  /* border: solid 1px; */
  width: 100%;
  height: auto;
  object-fit: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* border: solid 1px blue; */
}

.carouselBox .carouselTitle {
  font-weight: bolder;
  width: 100%;
}

.carouselBox .titleContent{
  font-weight: 500;
  font-size: 18px;
  
}

.carouselBox .carousel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  object-fit: cover;
  width: 100%;
  margin: 0 3%;
  border-top: solid 2px red;
}

.carouselBox .carousel-content {
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: center;
  object-fit: cover;
  /* margin: 0%; */
  padding: 3% 0%;
  /* border: solid 1px red; */
}

.carouselBox .carousel-card {
  flex: 0 0 auto;
  width: 240px;
  height: 300px;
  margin: 0 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  object-fit: cover;
  /* border: solid 1px yellow; */
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
transition: all 0.2s;

}

.carouselBox .carousel-card:hover{
  box-shadow: #C90913 0px 8px 15px;
}

.carouselBox .carousel-card a {
  width: 100%;
  height: 100%;
  background-color: white;
  /* border: solid 1px; */
}

.carouselBox .carousel-card img {
  width: 100%;
  height: 85%;
  margin-bottom: 10px;
  object-fit: cover;
  border: none;
}

.carouselBox .carousel-card h6 {
  position: absolute;
  margin: 5px 0;
  font-size: 0.9rem;
  text-align: center;
}

.carouselBox .carousel-button {
  background: #C8181C;
  color: white;
  border: none;
  border-radius: 0;
  padding: 10px;
  cursor: pointer;
  z-index: 1;
  position: relative;
  top: 5%;
  transform: translateY(-50%);
  font-size: 1.5rem;
  margin: 0% 2%;
  /* border:  solid blue 1px; */
  position: relative;
}

.carouselBox .prev-button {
  left: 10px;
  top: 25px;
}

.carouselBox .next-button {
  left: -10px;
  top: 25px;
}

@media (max-width: 1920px) {
  .carouselBox .carousel-content {
    margin: 0% 1%;
  }
  .carouselBox .carousel-card {
    width: 190px;
    height: 300px;
    margin: 0 10px;
  }
  .carouselBox .carousel-button {
    margin: 0%;
  }
}

@media (max-width: 1512px) {
  .carouselBox .carousel-content {
    margin: 0%;
  }
  .carouselBox .carousel-card {
    width: 180px;
    height: 280px;
  }
  .carouselBox .carousel-button {
    margin: 4% 1% 0 1%;
  }
}

@media (max-width: 1280px) {
  .carouselBox .carousel-card {
    width: 150px;
    height: 235px;
    margin: 0 10px;
  }
  .carouselBox .carousel-button {
    margin: 4% 1% 0 1%;
  }

  .carouselBox .titleContent {
    font-size: 14px;
  }
}

@media (max-width: 1200px) {
  .carouselBox .carousel-card {
    width: 160px;
    height: 250px;
    margin: 0 5px;
  }
  .carouselBox .carousel-button {
    margin: 4% 2% 0 2%;
  }
}

@media (max-width: 1024px) {
  .carouselBox {
    margin-top: 5%;
    margin-bottom: 5%;
  }
  .carouselBox .carousel-card {
    width: 135px;
    height: 210px;
    margin: 0 5px;
  }
  .carouselBox .carousel-button {
    margin: 5% 4% 0 4%;
  }

  .carouselBox .carousel-card img{
    margin-bottom: 5px;
  }
}

@media (max-width: 991px) {
  .carouselBox .carousel-card {
    width: 117px;
    height: 190px;
    margin: 0 5px;
  }
  .carouselBox .carousel-button {
    padding: 5px;
    margin: 5% 4% 0 4%;
  }

  .carouselBox .titleContent {
    font-size: 12px;
  }
}

@media (max-width: 835px) {
  .carouselBox .carousel-card {
    width: 103px;
    height: 160px;
    margin: 0 5px;
  }
  .carouselBox .carousel-button {
    padding: 0 4px;
    margin: 5% 4% 0 4%;
  }
  .carouselBox .titleContent {
    font-size: 10px;
  }
}

@media (max-width: 820px) {
  .carouselBox .carousel-card {
    width: 100px;
    height: 160px;
    margin: 0 5px;
  }
  .carouselBox .carousel-button {
    padding: 2px;
    margin: 5% 4% 0 4%;
  }
}

@media (max-width: 800px) {
  .carouselBox .carousel-card {
    width: 95px;
    height: 150px;
    margin: 0 5px;
  }
  .carouselBox .carousel-button {
    padding: 2px;
    margin: 5% 3% 0 3%;
  }
}

@media (max-width: 768px) {
  .carouselBox .carousel-card {
    width: 110px;
    height: 170px;
    margin: 0 5px;
  }
  .carouselBox .carousel-button {
    padding: 2px;
    margin: 5% 3% 0 3%;
  }
  .carouselBox a p {
    /* border: solid 1px red; */
    font-size: 11px !important;
  }
}

@media (max-width: 576px) {
  .carouselBox .carousel-card {
    width: 110px;
    height: 170px;
    margin: 0 3px;
    padding: 0;
  }
  .carouselBox .carousel-button {
    padding: 5px;
    margin: 5% 3% 0 3%;
  }
}

@media (max-width: 430px) {

  .carouselBox .carousel-card {
    width: 100px;
    height: 160px;
    margin: 0 5px;
  }
  .carouselBox .carousel-button {
    padding: 0px;
    margin: 0% 3% 0 3%;
  }

  /* .material-symbols-outlined{
    font-size: 12px;
  } */

  .carouselBox .titleContent {
    font-size: 8px;
  }
}
