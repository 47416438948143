.main-card {
  /* max-width: 450px; */
  font-family: Arial, sans-serif;
  padding: 0px;
  /* border-radius: 8px; */
}

.image-container {
  position: relative;
  text-align: center;
  color: white;
  height: 275px;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* border-radius: 8px; */
}

.category {
  position: absolute;
  top: 10px;
  left: 10px;
  font-family: unset;
}

.category-btn {
  background-color: #C90913;
  color: white;
  border: none;
  font-weight: 600;
  margin: 3px;
  cursor: pointer;
  /* border-radius: 7px; */
  font-size: 14px;
}

.image-container h2 {
  position: absolute;
  bottom: 25px;
  left: 15px;
  font-size: 20px;
  margin: 0;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 7px;
  /* border-radius: 4px; */
}

.image-container p {
  position: absolute;
  bottom: 15px;
  left: 15px;
  margin: 0;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 3px;
  /* border-radius: 4px; */
  font-size: 12px;
}

.sub-cards {
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* margin-top: 15px; */
}

.sub-card {
  display: flex;
  padding: 5px;
  cursor: pointer;
  /* border-radius: 4px; */
}

.sub-card img {
  width: 150px;
  height: 90px;
  object-fit: cover;
  margin-right: 8px;
  /* border-radius: 4px; */
}

.sub-card-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sub-card-text h3 {
  margin: 0 0 3px 0;
  font-size: 16px;
}
@media (max-width: 1024px) {

  .sub-card img {
    width: 120px;
    height: 70px;
  }
  .sub-card-text  h3{
    font-size: 14px !important;
  }
}
@media (max-width: 991px) {

  .sub-card img {
    width: 180px;
    height: 90px;
  }
  .sub-card-text  h3{
    font-size: 16px !important;
  }
}
@media (max-width: 820px) {

  .main-card {
    max-width: unset;
    margin-bottom: 20px;
  }
  
  .sub-card-text h3 {
    font-size: 22px;
  }

  .sub-card img{
    width: 150px;
    height: 90px;
  }
}

@media (max-width: 768px) {
  .main-card {
    max-width: 100%;
    /* padding: 0 10px; */
    margin-bottom: 20px;
    padding-top: 1.5rem;
  }

  .image-container {
    height: 200px;
  }

  .image-container img {
    height: 200px;
  }

  .sub-card {
    padding: 1px;
  }


  .sub-card img {
    width: 160px;
    height: 80px;
    margin-right: 8px;
  }

  .sub-card-text h3 {
    font-size: 16px;
  }

  .sub-card-text p {
    font-size: 10px;
  }
}
@media (max-width: 567px) {
  .sub-cards{
    margin-top: 10px;
    gap: 10px !important;
  }
  .sub-card {
    margin-top: 10px !important;
  }
  .sub-card-text h3 {
    font-size: 16px !important;
  }

  .sub-card img{
    margin-bottom: 0px;
    width: 160px;
    height: 100px;
  }
}
