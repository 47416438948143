.vertical-fixed-right {
  position: absolute;
  top: 300px;
  right: 0;
  width: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  padding: 0;
  margin-top: 25px;
}


.vertical-fixed-right img {
  max-width: 100%;
  height: auto;
  object-fit: contain;
}

.ads-right-slider-container {
  display: flex;
  flex-direction: column;
  transition: transform 0.5s ease-in-out;
}

.ads-right-slide {
  width: 100%;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1980px) {
  .vertical-fixed-right {
  top: 262px;
  }
  /* .vertical-fixed-right img {
    height: 900px;
  } */
}
@media (max-width: 1920px) {
  .vertical-fixed-right {
  top: 260px !important;
  }
  /* .vertical-fixed-right img {
    height: 792px;
  } */
}

@media (max-width: 1600px) {
  .vertical-fixed-right {
    top: 225px !important;
  }
  /* .vertical-fixed-right img {
    height: 730px;
  } */
}

@media (max-width: 1512px) {
  .vertical-fixed-right {
    top: 230px !important;
  }
  /* .vertical-fixed-right img {
    height: 640px;
  } */
}
@media (max-width: 1440px) {
  .vertical-fixed-right {
    /* width: 190px !important; */
    top: 220px !important;
  }
  /* .vertical-fixed-right img {
    
    height: 635px;
  } */
}
@media (max-width: 1368px) {
  .vertical-fixed-right {
    /* width: 190px !important; */
    top: 218px !important;
  }
  /* .vertical-fixed-right img {
    height: 640px;
  } */
}
@media (max-width: 1280px) {
  .vertical-fixed-right {
    /* width: 160px !important; */
    top: 210px !important;
  }
  /* .vertical-fixed-right img {
    height: 630px;
  } */
}
@media (max-width: 1200px) {
  .vertical-fixed-right {
    top: 210px !important;
  }
  /* .vertical-fixed-right img {
    height: 630px;
  } */
}
@media (max-width: 1024px) {
  .vertical-fixed-right {
  top: 275px !important;
  }
  /* .vertical-fixed-right img {
    height: 400px;
  } */
}
@media (max-width: 991px) {
  .vertical-fixed-right {
    width: 130px !important;
    top: 270px !important;
  }
  /* .vertical-fixed-right img {
    height: 500px;
  } */
}
@media (max-width: 835px) {
  .vertical-fixed-right img {
    height: 500px;
  }
  /* .vertical-fixed-right {
    top: 255px !important;
  } */
}
@media (max-width: 820px) {
  .vertical-fixed-right {
    display: none;
  }
}
/* @media (max-width: 800px) {
  .vertical-fixed-right img {
    height: 650px;
  }
  .vertical-fixed-right {
    top: 232px !important;
  }
  .vertical-fixed-left img {
    height: 650px;
  }
  .vertical-fixed-left {
    top: 232px !important;
  }
} */

