.hpTwo .colorRed {
    background-color: #F73C29;
  }
  .hpTwo .colorOrange {
    background-color: #E96F27;
  }
  .hpTwo .colorGreen {
    background-color: #277F45;
  }
  .hpTwo .colorSky{
    background-color: #52A79D;
  }
  .hpTwo .colorLightGreen {
    background-color: #1BB21D;
  }
  .hpTwo .colorBlue {
    background-color: #4B679B;
  }
  .hpTwo {
    margin: 2% 0% 3% 0%;
    padding: 0%;
  }
  .hpTwo .container {
    width: 100%;
    margin-left: 0%;
    margin-bottom: 1%;
    margin-top: 2%;
    background-color: transparent;
    object-fit: cover;
}
  .hpTwo .hp_banner_box {
    width: 155%;
    display: flex;
    justify-content: center;
    object-fit: cover;
    padding: 0%;
    margin-bottom: 1px;
    background-color: transparent !important;
  }
  .hpTwo .hp_banner_left,
  .hpTwo .hp_banner_right {
    flex: 1;
    width: 100%;
    height: 600px;
    max-height: 600px;
    object-fit: cover;
    overflow: hidden; 
  }
  .hpTwo .slider {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .hpTwo .slider-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .hpTwo .slider-container div {
    height: 100%;
    width: 100%;
  }
  .hpTwo .slider-container div a {
    width: 100%;
    height: 100% !important;
    object-fit: cover;
  }
  
  .hpTwo .slider-container div a img {
    width: 100%;
    height: 100% !important;
  }
  .hpTwo .slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    background-position: center;
    background-size: contain;
    opacity: 0;
    transition: opacity 0.7s ease;
  }
  .hpTwo .slide.active {
    opacity: 1;
  }
  .hpTwo .slide .slideImg {
    width: 110%;
    height: auto;
    object-fit: cover;
    display: block;
  }
  
  .hpTwo .prev,
  .hpTwo .next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 28px;
    padding: 10px;
    z-index: 10;
  }
  .hpTwo .prev {
    left: 10px;
  }
  .hpTwo .next {
    right: 10px;
  }
  .hpTwo .numbers {
    text-align: center;
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
  }
  .hpTwo .number {
    
    display: none;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #bbb;
    margin: 0 4px;
    cursor: pointer;
    line-height: 20px;
    text-align: center;
    transition: background-color 0.3s ease;
  }
  .hpTwo .slide-title {
    position: absolute;
    top: 1%;
    right: 2%;
    width: 200px !important;
    height: 32px !important;
    background-color: #F73C29;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    color: white;
    z-index: 10;
  }
  .hpTwo .slide-title h3{
    font-size: 11px;
    width: 100%;
    height: 100%;
    font-weight: bold;
    font-size: x-large;
  }
  .hpTwo .slide-content {
    position: absolute;
    height: 80px !important;
    bottom: 0;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.7) 25%,
      rgba(0, 0, 0, 1) 100%
    );  display: flex;
    justify-content: start;
    align-items: center;
    padding-left: 1%;
    /* border-radius: 5px; */
    color: white;
    font-size: 28px;
    line-height: 24px;
  
  }
  
  
  .hpTwo .number:hover {
    background-color: #717171;
    color: white;
  }
  .hpTwo .number.active-number {
    background-color: #717171;
    color: white;
  }
  .hpTwo .newsTitle {
    width: 100%;
    height: 600px;
  }
  .hpTwo .newsTop,
  .hpTwo .newsDown {
    height: 300px;
    display: flex;
  }
  .hpTwo .downLinks .imgBox img {
    height: 200px;
  }
  .hpTwo .imgBox {
    position: relative;
    width: 100%;
    max-width: 800px;
    height: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    transition: all ease-in-out 0.3s;
    object-fit: cover;
    overflow: hidden;
    border: solid 1px white;
  }
  .hpTwo .imgBox:hover {
    filter: none;
    cursor: pointer;
  }
  .hpTwo .imgBox:hover img {
    transform: scale(1.1);
    transition: all ease-in-out 0.3s;
  }
  .hpTwo .image {
    display: inline-flex;
    width: 100%;
    height: 100%;
    display: block;
  }
  .hpTwo .overlay {
    position: absolute;
    top: -10%;
    left: 3%;
    padding: 0% 2%;
    border-radius: 4px;
    font-size: 11px;
    font-weight: 500;
    z-index: 2;
    color: white;
  }
  .hpTwo .overlayTwo {
    position: absolute;
    width: 100%;
    height: 23%;
    bottom: 0%;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.5) 25%,
      rgba(0, 0, 0, 0.8) 100%
    );
    z-index: 2;
    display: flex;
    /* padding-bottom: 1% !important; */
    /* border: solid blue 1px; */
  }
  .hpTwo .overlayTwo p {
    display: inline-block;
    width: 100%;
    height: 100%;
    z-index: 1;
    font-weight: 400;
    font-size: 16px !important;
    display: flex;
    justify-content: start;
    align-items: center;
    padding-left: 3%;
    padding-bottom: 0%;
    padding-top: 5%;
    color: white;
  }
  .hpTwo .overlayTwo::before {
    content: "";
    position: absolute;
    top: 0%;
    left: 0;
    width: 100%;
    height: 100%;
    /* border: solid blue; */
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.0) 0%,
      rgba(0, 0, 0, 0.5) 25%,
      rgba(0, 0, 0, 0.9) 100%
    );
  }
  .hpTwo .title {
    background-color: #333;
    color: #fff;
    padding: 10px;
    text-align: center;
  }
  .hpTwo .downLinks {
    width: 156%;
    height: 100% !important;
    padding: 0%;
    display: flex;
    flex-direction: column;
    object-fit: cover;
    overflow: hidden;
  }
  .hpTwo .downLinks .row {
    width: 100%;
    height: 100% !important;
  }
  
  .hpTwo .downLinks .row .col-4 .imgBox img {
    border: solid 1px white;
    height: 100%;
  }
  .hpTwo .downLinks .row .col-4 {
    padding: 0;
  }
  @media screen and (max-width: 1980px) {
    .hpTwo .hp_banner_box {
        width: 118%;
      }
      .hpTwo .hp_banner_left,
      .hpTwo .hp_banner_right {
        width: 100%;
        height: 500px;
        max-height: 500px;

      }
    .hpTwo .downLinks {
        width: 119%;
      }
      .hpTwo .newsTitle {
        width: 100%;
        height: 500px;
      }
      .hpTwo .newsTop,
      .hpTwo .newsDown {
        height: 250px;
      }
  }
  @media screen and (max-width: 1920px) {
    .hpTwo .hp_banner_box {
        width: 100%;
      }
      .hpTwo .hp_banner_left,
      .hpTwo .hp_banner_right {
        width: 100%;
        height: 400px;
        max-height: 400px;
      }
    .hpTwo .downLinks {
        width: 101%;
      }
      .hpTwo .downLinks .overlayTwo{
        height: 50px;
      }
      .hpTwo .downLinks .overlayTwo p{
        margin-top: -2%;
      }
      .hpTwo .newsTitle {
        width: 100%;
        height: 400px;
      }
      .hpTwo .newsTop,
      .hpTwo .newsDown {
        height: 200px;
      }
  }
  @media screen and (max-width: 1512px) {
    .hpTwo .hp_banner_box {
        width: 105%;
      }
      .hpTwo .hp_banner_left,
      .hpTwo .hp_banner_right {
        width: 100%;
        height: 500px;
        max-height: 500px;
      }
    .hpTwo .downLinks {
        width: 106%;
      }
      .hpTwo .newsTitle {
        width: 100%;
        height: 500px;
      }
      .hpTwo .newsTop,
      .hpTwo .newsDown {
        height: 250px;
      }
      .hpTwo .downLinks .overlayTwo{
        height: 60px;
      }
      .hpTwo .downLinks .overlayTwo p{
          font-size: 20px !important;
        margin-top: -2%;
      }
  }
  @media screen and (max-width: 1280px) {
    .hpTwo .hp_banner_box {
        width: 100%;
      }
      .hpTwo .hp_banner_left,
      .hpTwo .hp_banner_right {
        width: 100%;
        height: 500px;
        max-height: 500px;
      }
    .hpTwo .downLinks {
        width: 101%;
      }
      .hpTwo .newsTitle {
        width: 100%;
        height: 500px;
      }
      .hpTwo .newsTop,
      .hpTwo .newsDown {
        height: 250px;
      }
    .hpTwo .slide-content{
      padding-top: 2%;
      line-height: 28px;
    }
  }
  @media screen and (max-width: 1200px) {
    .hpTwo .hp_banner_left,
    .hpTwo .hp_banner_right {
      width: 100%;
      height: 400px;
      max-height: 400px;
    }
  .hpTwo .downLinks {
      width: 101%;
    }
    .hpTwo .newsTitle {
      width: 100%;
      height: 400px;
    }
    .hpTwo .newsTop,
    .hpTwo .newsDown {
      height: 200px;
    }
  }
  @media screen and (max-width: 991px) {
    .hpTwo .hp_banner_box {
        width: 110%;
      }
      .hpTwo .hp_banner_left,
      .hpTwo .hp_banner_right {
        width: 110%;
        height: 274px;
        max-height: 274px;
      }
    .hpTwo .downLinks {
        width: 111%;
      }
      .hpTwo .newsTitle {
        width: 100%;
        height: 274px;
      }
      .hpTwo .newsTop,
      .hpTwo .newsDown {
        height: 137px;
      }
    .hpTwo .downLinks .row .col-4 .imgBox img {
      border: solid 1px white;
      height: 100%;
    }
  }
  @media screen and (max-width: 835px) {
    .hpTwo .hp_banner_box {
        width: 100%;
        display: flex;
        flex-direction: column;
      }
      .hpTwo .hp_banner_left,
      .hpTwo .hp_banner_right {
        width: 100%;
        height: 350px;
        max-height: 350px;
      }
      .hpTwo .hp_banner_left .slider .slider-container{
        height: 100% !important;
      }
    .hpTwo .downLinks {
        width: 102%;
      }
      .hpTwo .newsTitle {
        width: 100%;
        height: 300px;
      }
      .hpTwo .newsTop,
      .hpTwo .newsDown {
        height: 150px;
      }
      .hpTwo .slide-content{
        height: 60px !important;
        padding-top: 0%;
        font-size: 14px;
        line-height: 16px;
      }
      .hpTwo .slide-title{
        width: 130px !important;
        height: 25px !important;
      }
      .hpTwo .slide-title h3{
        margin-top: 2%;
        font-size: 17px;
      }
      .hpTwo .overlay {
        top: -25%;
        font-size: 8px;
      }
      .hpTwo .overlayTwo p {
        font-size: 10px !important;
        margin-left: 2%;
      } 
    .hpTwo .downLinks .row .col-4 .imgBox img {
      border: solid 1px white;
      height: 100%;
    }
  }
  @media screen and (max-width: 820px) {
    .hpTwo .hp_banner_box {
        width: 100%;
      }
      .hpTwo .hp_banner_left,
      .hpTwo .hp_banner_right {
        width: 100%;
        height: 300px;
        max-height: 300px;
      }
      .hpTwo .hp_banner_left .slider .slider-container{
        height: 100% !important;
      }
    .hpTwo .downLinks {
        width: 102%;
      }
      .hpTwo .newsTitle {
        width: 100%;
        height: 300px;
      }
      .hpTwo .newsTop,
      .hpTwo .newsDown {
        height: 150px;
      }
      .hpTwo .slide-content{
        height: 60px !important;
        padding-top: 0%;
      }
      .hpTwo .slide-content p{
        margin-bottom: 10px;
        font-size: 16px;
        line-height: 16px;
      }
      .hpTwo .slide-title{
        width: 130px !important;
        height: 25px !important;
      }
      .hpTwo .slide-title h3{
        margin-top: 2%;
        font-size: 17px;
      }
      .hpTwo .overlay {
        top: -25%;
        font-size: 10px;
      }
      .hpTwo .overlayTwo p {
        font-size: 14px !important;
        margin-left: 2%;
        margin-bottom: 2%;
      } 
      .hpTwo .downLinks .overlay{
        margin-top: 5%;
      }
      .hpTwo .downLinks .overlayTwo{
        height: 60px;
      }
      .hpTwo .downLinks .overlayTwo p{
          font-size: 14px !important;
        margin-top: -2%;
      }
    .hpTwo .downLinks .row .col-4 .imgBox img {
      border: solid 1px white;
      height: 100%;
    }
  }
  @media screen and (max-width: 768px) {
    .hpTwo .hp_banner_box{
      display: flex;
      flex-direction: column;
    }
    .hpTwo .hp_banner_left,
      .hpTwo .hp_banner_right {
        height: 300px;
        max-height: 300px;
      }
     .downLinks {
        width: 102%;
      }
      .hpTwo .newsTitle {
        height: 300px;
      }
      .hpTwo .newsTop,
      .hpTwo .newsDown {
        height: 150px;
      }

      .hpTwo .overlay {
        top: -25%;
        font-size: 10px;
      }
      .hpTwo .overlayTwo p {
        font-size: 12px !important;
        margin-top: 0%;
        margin-left: 2%;
      } 
      .hpTwo .slide-content{
        height: 60px !important;
        padding-top: 5%;
        margin-bottom: 0px !important;
        font-size: 18px;
        line-height: 16px;
      }
      .hpTwo .slide-content p{
        width: 100%;
        height: 100%;
        font-size: 18px;
        line-height: 16px;
      }
    .hpTwo .downLinks .row .col-4 .imgBox img {
      border: solid 1px white;
      height: 100%;
    }
  }
  @media screen and (max-width: 576px) {
    .hpTwo{
      margin-left: 2%;
    }
    .hpTwo .slide-content{
      height: 60px !important;
      padding-top: 6%;
      margin-bottom: 0px !important;
      font-size: 18px;
      line-height: 16px;
    }
    .hpTwo .slide-content p{
      width: 100%;
      height: 100%;
      font-size: 18px;
      line-height: 16px;
    }
    .hpTwo .downLinks {
     width: 100%;
      transition: all ease-in-out 0.3s;
    }
    .hpTwo .downLinks .row {
      margin: 0 !important;
    }
    .hpTwo .downLinks .row .col-4 {
      padding: 0 !important;
    }
    .hpTwo .overlay {
      top: -20%;
      font-size: 8px;
    }
    .hpTwo .overlayTwo p {
      font-size: 10px !important;
      margin-top: 0%;
      margin-left: 2%;
    } 
    .hpTwo .downLinks .row .col-4 .imgBox img {
      border: solid 1px white;
      height: 100%;
    }
  }
  @media screen and (max-width: 430px) {  
    .hpTwo .downLinks {
     width: 100%;
      transition: all ease-in-out 0.3s;
    }
    .hpTwo .downLinks .row {
      margin: 0 !important;
    }
    .hpTwo .downLinks .row .col-4 {
      padding: 0 !important;
    }
    .hpTwo .overlay {
      top: -20%;
      font-size: 8px;
    }
    .hpTwo .downLinks .overlay {
      top: -22%;
      font-size: 9px;
    }
    .hpTwo .overlayTwo{
      height: 30px;
    }
    .hpTwo .overlayTwo p {
      font-size: 9px !important;
      margin-top: 0%;
      margin-left: 2%;
    } 
    .hpTwo .downLinks .row .col-4 .imgBox img {
      border: solid 1px white;
      height: 100%;
    }
  }
  