.about-container {
    margin-top: 30px;
}

.aboutpage-title1 {
    width: 100%;
    color: white;
    font-weight: 600;
    font-size: 24px;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-bottom: 15px;
    text-align: center;
    background-color: #C90913;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

@media screen and (max-width: 1024px) {
    .about-container {
        padding-left: 200px;
        padding-right: 200px;
    }

}

@media screen and (max-width: 820px) {
    .about-container {
        padding-left: 80px;
        padding-right: 80px;
    }
}

@media screen and (max-width: 768px) {
    .about-container {
        padding-left: 15px;
        padding-right: 15px;
    }
}