.slider-card {
  width: 100%;
  max-width: 445px;
  height: 890px;
}

.slider-main-card {
  position: relative;
  text-align: center;
  color: white;
}

.main-card-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.category-slider-btn {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #00aaff;
  color: white;
  border: none;
  padding: 3px 7px;
  margin: 3px;
  cursor: pointer;
  border-radius: 7px;
  font-size: 15px;
  font-weight: 600;
}

.main-card-text {
  position: absolute;
  bottom: 10px;
  left: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px;
  text-align: left;
}

.sub-cards {
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* margin-top: 10px; */
  transition: opacity 0.5s ease-in-out;
}

.fade-in {
  opacity: 1;
}

.fade-out {
  opacity: 0;
}

.sub-card {
  display: flex;
  align-items: center;
  gap: 10px;
  transition: transform 0.3s ease;
}
.sub-card:hover {
  transform: scale(1.05);
}

.sub-card-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.sub-card-text {
  flex: 1;
  text-align: left;
}

.slider-card .navigation {
  display: flex;
  margin: 15px 7px 20px;
  line-height: 1;
}
.slider-card .navigation > span {
  padding: 8px 12px;
  display: inline-block;
  margin-right: 3px;
  cursor: pointer;
  transition: background-color 300ms, color 300ms;
  -moz-transition: background-color 300ms, color 300ms;
  -o-transition: background-color 300ms, color 300ms;
  -webkit-transition: background-color 300ms, color 300ms;
  background-color: #e9e9e9;
}
.slider-card .navigation > span:hover {
  background-color: gray;
}
.slider-card .navigation > span:hover i {
  color: #ffff;
}
@media (max-width: 375px) {
  .slider-main-card {
      width: 112% !important;
  }
}

@media (max-width: 768px) {
  .slider-card {
    margin-top: 30px;
    width: 298px;
    height: auto;
    margin-left: 0 !important;
  }
  .slider-cards-content {
    flex-direction: column !important;
  }
  .navigation {
    margin: 15px 7px 20px 0;
  }
  .slider-main-card {
    width: 130%;
  }
}
